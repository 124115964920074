import { partition } from 'lodash';

import { SettingChangedSource, settingPath } from '@/features/settings/setting-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface SettingChangedProperties {
  [mpProps.eventSource]?: SettingChangedSource;
  [mpProps.gameClassId]?: number;
  [mpProps.queueId]?: number;
  [mpProps.settingPath]: string;
  [mpProps.settingValue]: unknown;
}

interface SettingResetProperties {
  [mpProps.eventSource]?: SettingChangedSource;
  [mpProps.gameClassId]?: number;
  [mpProps.queueId]?: number;
  [mpProps.settingPath]: string;
}

interface CustomSettingsLoadedProperties {
  [mpProps.settings]: {
    [mpProps.gameClassId]?: number;
    [mpProps.queueId]?: number;
    [mpProps.settingPath]: string;
    [mpProps.settingValue]: unknown;
  }[];
}

interface GamesAutoLaunchEnabledLoaded {
  [mpProps.enabledGameClassIds]: (string | number)[];
  [mpProps.disabledGameClassIds]: (string | number)[];
  [mpProps.gameAutoLaunchSettings]: {
    gameClassId: number;
    autoLaunchEnabled: boolean;
  }[];
}

export interface MixpanelSettingEventMap {
  'Setting Changed': SettingChangedProperties;
  'Setting Reset': SettingResetProperties;
  'Settings Loaded': CustomSettingsLoadedProperties;
  'Game Auto Launch Settings Loaded': GamesAutoLaunchEnabledLoaded;
}

function shouldTrackSettingName(name: string) {
  if (name.startsWith(settingPath.montage.$path)
    || name.startsWith(settingPath.icDisabledGame.selected.$path)
  ) {
    return true;
  }
  switch (name) {
    case settingPath.app.launchDesktopOnGameClose.$path:
    case settingPath.appearance.inGameSecondaryWindow.enabled.$path:
    case settingPath.streamRecorder.audio.mic.enable.$path:
    case settingPath.streamRecorder.audio.mic.inputMode.$path:
    case settingPath.streamRecorder.captureMode.$path:
    case settingPath.streamRecorder.autoRecord.endDelay.$path:
    case settingPath.streamRecorder.instantReplay.enable.$path:
    case settingPath.streamRecorder.instantReplay.pastDuration.$path:
    case settingPath.videoLibrary.recycleBinAutomaticCleanup.enabled.$path:
    case settingPath.videoLibrary.recycleBinAutomaticCleanup.durationDays.$path:
    case settingPath.videoLibrary.filesizeWarning.enabled.$path:
    case settingPath.videoLibrary.filesizeWarning.threshold.$path:
    case settingPath.videoLibrary.filesizeWarning.managementStrategy.$path:
    case settingPath.videoReplay.showApmGraph.$path:
    case settingPath.webcam.enabled.$path:
      return true;
    default:
      return false;
  }
}

function changed(params: {
  eventSource: SettingChangedSource;
  gameClassId?: number;
  queueId?: number;
  settingName: string;
  settingValue: unknown;
}): MixpanelEventParams<'Setting Changed'> {
  const {
    eventSource,
    gameClassId,
    queueId,
    settingName,
    settingValue,
  } = params;

  return [
    'Setting Changed',
    {
      [mpProps.eventSource]: eventSource,
      [mpProps.gameClassId]: gameClassId,
      [mpProps.queueId]: queueId,
      [mpProps.settingPath]: settingName,
      [mpProps.settingValue]: settingValue,
    },
  ];
}

function reset(params: {
  // eventSource: SettingChangedSource;
  gameClassId?: number;
  queueId?: number;
  settingName: string;
}): MixpanelEventParams<'Setting Reset'> {
  const {
    // eventSource,
    gameClassId,
    queueId,
    settingName,
  } = params;

  return [
    'Setting Reset',
    {
      // [mpProps.eventSource]: eventSource,
      [mpProps.gameClassId]: gameClassId,
      [mpProps.queueId]: queueId,
      [mpProps.settingPath]: settingName,
    },
  ];
}

function loaded(params: {
  settings: {
    gameClassId?: number;
    queueId?: number;
    settingName: string;
    settingValue: unknown;
  }[];
}): MixpanelEventParams<'Settings Loaded'> {
  const { settings } = params;
  return [
    'Settings Loaded',
    {
      [mpProps.settings]: settings.map(({ gameClassId, queueId, settingName, settingValue }) => ({
        [mpProps.gameClassId]: gameClassId,
        [mpProps.queueId]: queueId,
        [mpProps.settingPath]: settingName,
        [mpProps.settingValue]: settingValue,
      })),
    },
  ];
}

function gamesAutoLaunchEnabledLoaded(
  gameAutoLaunchSettings: overwolf.settings.games.AutolaunchEnabledResult[],
): MixpanelEventParams<'Game Auto Launch Settings Loaded'> {
  const sanitized = gameAutoLaunchSettings.map(({ gameClassId, autoLaunchEnabled }) => {
    return {
      gameClassId,
      autoLaunchEnabled,
    };
  });
  const [enabled, disabled] = partition(sanitized, ({ autoLaunchEnabled }) => autoLaunchEnabled);
  return [
    'Game Auto Launch Settings Loaded',
    {
      [mpProps.enabledGameClassIds]: enabled.map(({ gameClassId }) => gameClassId),
      [mpProps.disabledGameClassIds]: disabled.map(({ gameClassId }) => gameClassId),
      [mpProps.gameAutoLaunchSettings]: sanitized,
    },
  ];
}

export const settingHelpers = {
  changed,
  reset,
  loaded,
  gamesAutoLaunchEnabledLoaded,
  shouldTrackSettingName,
};
